import React, {useState, useRef, useEffect, memo} from 'react';
import {ReactComponent as PlayIcon} from './../../assets/play.svg'
import {Spinner} from "../../components/spinner.component";
import config from './../../utils/config.app.js';
import {Animation} from '../../components/animation.component'
import {checkIfSuccessful, dict2arr} from "../../utils/fetch.utils.js";

import HandsHeartReaction from './assets/hands-heart-reaction.png';

import "./scss/video-player.scss"



export const VideoPlayer = ({slide,/* videoKey,videoSrc, previewSrc, */isPlaying, controlsCallback})=>{
	
	// let slide ={duration:10, timeline_reactions:{}}
	let video = useRef(null);
	let timeline = useRef(null);
	var [isActuallyPlaying, setActuallyPlaying] = useState(false);
	var [isBuffering, setBuffering] = useState(false); 
	var [isHot, setHot] = useState(false);// this determines if user had interacted with the video and it should be loaded
	var [progress, setProgress] = useState(0);
	var [isDragging, setIsDragging] = useState(false);
	var [isReactionPlaying, setIsReactionPlaying] = useState(false);

	const onPlayClick = (slide_key, isPlaying)=>
	{
		// console.log('onPlayClick')
		controlsCallback(slide.slide_key, isPlaying ? 'pause' : 'play');
	}

	const controlsCallbackWrapper = (slide, context, params)=>
	{
		controlsCallback(slide.slide_key, context, params);
	}




	const updateTimeElapsed = () => {
	if (video.current != null){

			var currentTime = video.current.currentTime;
			setProgress(currentTime)  


			// console.log(currentTime)

			
			// if animation is already playing
			// do noting..
			if (!isReactionPlaying)
			{


				dict2arr(slide.timeline_reactions).map((item)=>{
					// console.log(item.position - currentTime)
					if (Math.abs(item.position - currentTime) < 0.2)
					{
						// console.log('must play', item);
						setIsReactionPlaying(true)
					}
					// let d = titem


				})
			}

		}
	}

	const getDuration = (video)=>
	{
		if (!video) return 0;

		if(Number.isNaN(video.duration)) return 0

		// console.log('getDuration', video, video.duration)
		return video.duration;
	}

	const raw2decimalTime= (time, duration)=>
	{
		// console.log('raw2decimalTime', time, duration)
		if (duration)
		{
			return Math.round(time / duration*100)  
		}
		else
		{
			return 0
		}

	}


	// converts fractional seconds (4.12) to a nice format of 4:xx
	const formatTime= (fracTime) => {

		const lz=(x, p)=>
		{
			while ((''+x).length < p ) x='0'+x
			return x
		}
		// [todo] fix this when we have video that is longer

		let seconds= Math.floor(fracTime)

		let msec = Math.round((fracTime - seconds)*1000);

		let minutes = Math.floor(seconds / 60)
		let hours = Math.floor(minutes / 60)

		seconds -= minutes * 60
		minutes -= hours * 60

		let hoursStr = hours >0 ? lz(hours,2)+':' : ''
		let minutesStr = lz(minutes,2)+':' 
		let secondsStr = lz(seconds,2)
		let msecStr =  '.'+lz(msec,4)


		// console.log('formatTime', fracTime, hoursStr+minutesStr+secondsStr)
		return hoursStr+minutesStr+secondsStr//+msecStr

	}


	function onButtonClick() {
		// This will allow us to play video later...
		video.load();
		fetchVideoAndPlay();
	}



	// https://developer.chrome.com/blog/play-request-was-interrupted
	function fetchVideoAndPlay(src) 
	{
		// console.log('fetchVideoAndPlay', src);
		setBuffering(true)
		fetch(src)
			.then(response => response.blob())
			.then(blob => {
				video.srcObject = blob;
				return video.play();
			})
			.then(_ => {
				// Video playback started ;)
				 setBuffering(false);
				 setActuallyPlaying(true)
			})
			.catch(e => {
				// Video playback failed ;(
				 setBuffering(false);
				 console.log('playback failed..')
			})
	}


	const onTimelineClick = (e, timeline)=>
	{
		if (!slide.preview_url) return;
				// e = Mouse click event.
		let rect = timeline.getBoundingClientRect();
		let x = e.clientX - rect.left; 

		// this is progress in %
		let prc = x / timeline.offsetWidth;



		// we should scroll to this timecode
		let timeCode = getDuration(video.current) * prc;

		video.current.currentTime = timeCode;
		setProgress(timeCode)

		e.preventDefault();
		e.stopPropagation();
	}


		useEffect(()=>{

			if (!slide.preview_url) return;
			video.current.addEventListener("timeupdate", updateTimeElapsed);
			
				// console.log('isPlaying', isPlaying)
			if (isPlaying)
			{
				//fetchVideoAndPlay(config.contentServiceUrl+slide.preview_url)

				if (!isHot)
				{
					setHot(true);
					setBuffering(true)
				}

				video.current.play().then(()=>{
					setActuallyPlaying(true)
					setBuffering(false)
				}).catch((e)=>{


					console.log('Somethign went wrong with playing the video', e)
				})

				
				
			}
			else
			{
				if (isActuallyPlaying)
				{
					video.current.pause()
					setActuallyPlaying(false)	
				}
				
			}

			return ()=>{
				if (video.current !== null)
				{
					video.current.removeEventListener("timeupdate", updateTimeElapsed);	
				}
				
			}

	})

	const onTimelineDragStart = (event) =>{

		setIsDragging(true);
		// onPlayClick(null, false);
		// video.current.pause()
		event.preventDefault()
		event.stopPropagation();
	}

	const onTimelineDragEnd = (event) =>{
		// onTimelineClick(event, timeline.current);
		setIsDragging(false);
		event.preventDefault();
		event.stopPropagation();
	};
	const onTimelineDragging = (event)=>{
		if (!slide.preview_url) return;

		if (isDragging)
		{

			let rect = event.target.getBoundingClientRect();
			let pos = event.clientX - rect.left;
			let prc = pos / event.target.offsetWidth;

			let timeCode = getDuration(video.current) * prc;

			video.current.currentTime = timeCode;


			// console.log('onTimelineDragging', pos, prc)	
		}
		
		// event.preventDefault();
		// event.stopPropagation();
		// return false;
		event.preventDefault();
	}



	const onTimelineReactionClick = (event, item)=>{

		// console.log(item);
		video.current.currentTime = item.position;
		event.preventDefault();
		event.stopPropagation();
	}

	let reactions = [
			HandsHeartReaction
		];

	return (
		<div className="video-player">

			<video 
				ref={video} 
				poster={isActuallyPlaying ? null :  (slide.placeholder ? slide.thumbnail_url : config.contentServiceUrl+slide.thumbnail_url)}
				preload="auto"
				muted  
				inline="true"
				playsInline
				loop >
				{isHot ? <source src={slide.placeholder ? slide.preview_url : config.contentServiceUrl+slide.preview_url} type="video/mp4" /> : null}
				
			</video>
			<div 
				className="controls-overlay" 
				onClick={()=>onPlayClick(slide.slide_key, isPlaying)}
			>
				

				<Animation
					// frames={images}
					path="/static/animation4/frame-"
					imagesCount={78}
					isPlaying={isReactionPlaying}
					// frameRate={33.33 * skipFrames}
					onAnimationEnd={  ()=>{
						setIsReactionPlaying(false)
						return false;
					}}

				/>




				<button 
					className="play-button"
					
				>
						{isHot 
							? (isBuffering ? <Spinner/> :  (!isActuallyPlaying ? <PlayIcon /> : null))
							: <PlayIcon/>}
						{}
						
					 
				</button>
				<div 
					ref={timeline}
					className={"timeline " + (isActuallyPlaying ? 'timeline-hidden' : '')}
					onClick={(event)=>onTimelineClick(event, timeline.current)}
					onMouseDown={onTimelineDragStart}
					onMouseUp={onTimelineDragEnd}
					onMouseLeave={()=>setIsDragging(false)}
					onMouseMove={onTimelineDragging}
				>
					<div className="progress" style={{width:raw2decimalTime(progress,  getDuration(video.current))+'%'}}> </div>
					{isHot ? dict2arr(slide.timeline_reactions).map((item)=>(
								<button
									key={item.dict_key}
									className="reaction_indicator"
									onClick={(e)=>onTimelineReactionClick(e, item)}
									style={{
										left: ( raw2decimalTime(item.position, getDuration(video.current))) + '%'
									}}
								>
									{item.reaction}
								</button>
							)
						) : null}
					{!isActuallyPlaying ? <div className="total-time">{formatTime(getDuration(video.current) )}</div> : null}
					{!isActuallyPlaying || true ? <div className="current-time">{formatTime(progress)}</div> : null}
				</div>
				
				{ !isActuallyPlaying && isHot && (progress>0)? (  
								<div className="reactions">
									<ul>
										{
											// ['😍','😀','🥰','👍','👏'].map((item,i)=>(

											reactions.map((image_resource,i)=>(
											<li key={i}>
												<a 
													// onClick={(e)=>onReactionClick(e,item)} 
													onClick={(event)=>{
														controlsCallbackWrapper(slide, 'timeline_reaction', {reaction : '🫶', position : progress} );
														// event.stopPropagation();
														setIsReactionPlaying(true);
														event.preventDefault();
													}}
													href="#"
												>
													<img src={image_resource} />
												</a>
											</li>)
										)}
									</ul>
								</div>) : null
							}



						</div>

		</div>
	);
}
