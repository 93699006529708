import React, {useState, useRef, useEffect, memo} from 'react';
import {checkIfSuccessful, dict2arr} from "./../../utils/fetch.utils.js";
import config from './../../utils/config.app.js';
import {VideoPlayer} from './video-player'
import "./scss/post.scss"
import {ChatComponent} from './../../components/chat.component'
import {ContentActions} from './content-actions'
// import {CarouselComponent} from './carousel'

import Carousel from 'react-elastic-carousel'


import {ReactComponent as SlideLeftIcon} from './assets/slide-left.svg'
import {ReactComponent as SlideRightIcon} from './assets/slide-right.svg'




export const PostComponent = ({item, defaultActiveSlide, controlsCallback, currentlyPlaying, chatOpen, toggleChatCallback,chatMessageSubmitHandler, contentActionsClickHandler})=>{


	let [activeSlideIndex, setActiveSlideIndex] = useState(defaultActiveSlide);

	const shouldSlideBePlaying = (slide, index, activeSlideIndex,currentlyPlaying)=>{
		let isPlayingCurrentSlide = false
		if (currentlyPlaying[0] === true){ // we shall play this post..
			if (currentlyPlaying[1]=== null){ // whatever slide is currently active
				

				//  when we will have carousel, play whatever slide is active
				isPlayingCurrentSlide = (index == activeSlideIndex)
			}
			else
			{
				isPlayingCurrentSlide = currentlyPlaying[1] == slide.slide_key
			}

		}
		return isPlayingCurrentSlide;
	}




	
	const renderCarouselArrows = (post, type, isEdge, onClick)=>{
		if ( dict2arr(post.slides).length  < 2) return <></>; 
		return (
			<button 
				className={"rec-arrow " + "rec-arrow-"+type.toLowerCase()+(isEdge ? ' rec-arrow-edge ':'')}
				onClick={onClick}
			>
				{type =='PREV' ? <SlideLeftIcon/> : <SlideRightIcon/>}
			</button>);
	}


	const onCarouselChange = (post, slide, slideIndex)=>
	{
		// console.log(post, slide, slideIndex)
		setActiveSlideIndex(slideIndex);
		controlsCallback(post, slide, 'carousel-change', {slideIndex:slideIndex})
	}

	const RenderPagination = ({pages, activePage, onClick})=>{


		let newPages = pages.length > 8 ? pages.slice(8) : (pages.length < 2 ? [] : pages)

		return (
			<div className="rec rec-pagination">

				{newPages.map((index)=>{
					
					// console.log('index',index);
					return (
						<button 
							key={index}
							tabIndex={index-1} 
							className={"rec rec-dot rec " + (index == activePage ? 'rec-dot_active':'') }
							type="button"
							onClick={onClick}
						></button>
					);
				})}
			</div>
			);
			
		
	}



	
	
				


	return (


		<>
			<div
				className={"post-component " + (chatOpen ? 'chat-open': '' )}
				id={item.key}
			>
				{process.env.NODE_ENV == 'development'  && false? (
					<div className="debug-info">
						<div>
							<a 
								href="#"
								onClick={(e)=>{
									console.log(item)
								}}
							>
								{item.key} : {item.is_invitation ? 'invite': 'collab'}
							</a>
						</div>
					</div>

					) : null}

				<ChatComponent 
					post={item}
					chatOpen={chatOpen} 
					onSubmit={(msg, parent)=>chatMessageSubmitHandler(msg, parent, item)}
					onClose={()=>toggleChatCallback(false)} //props.closeChatHandler(contentItem)}
					onPreviewClick={()=>toggleChatCallback(!chatOpen)}//(e,post)=>{props.slideClickHandler(e,post, 'toggle-chat')}
				/>
				

				<Carousel 
					className="carousel-wrapper"
					itemsToShow={1}
					initialActiveIndex={defaultActiveSlide}
					// enableMouseSwipe={false}
					// enableSwipe={!chatOpen}
					renderArrow={({type, onClick,isEdge})=>renderCarouselArrows(item, type, isEdge, onClick)}
					onChange = {(carouselSlide, index)=>onCarouselChange(item, carouselSlide.item.slide, index) }
					renderPagination={RenderPagination}
				>
					{dict2arr(item.slides).map((slide,index)=>{
						return (
							<VideoPlayer
								key={slide.slide_key}
								slide={slide}
								isPlaying={shouldSlideBePlaying(slide, index, activeSlideIndex, currentlyPlaying)}
								controlsCallback={(videoKey, context, params)=> controlsCallback(item, slide, context, params)}

							/>
						)
					})}
				</Carousel>


				
				<ContentActions 
				onClickHandler={(action)=>contentActionsClickHandler(item, action)} 
				content_item={item}
			/>
			</div>

		</>

	)

		
}
