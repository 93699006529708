import React, {useState, useEffect, useRef} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import {timeSince} from '../utils/time-since.js';

import {performUserSearch} from '../services/user-service.js'
import { TopNav } from "../components/top-nav.component";
import { UserBar } from "../components/user-bar.component";
import { BottomNav } from "../components/bottom-nav.component";
// import { SearchInput } from "../components/search-input.component";
import { Input } from "../components/form.input.component";
import { UploadModal } from "../components/upload-modal/upload-modal-alt.component";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/dm-view.scss"

import { AvatarComponent} from "../components/avatar.component"

import User from "../utils/userdata.app.js";
import config from "../utils/config.app.js";
import {dict2arr, checkIfSuccessful} from "../utils/fetch.utils.js";






const setUploadModalOpen = ()=>{}



const DMItem = ({item})=>
{

	// chat, replies, upload, timeline_reaction, invite

	let message = ''

	switch (item.kind)
	{
		case 'chat':
		case 'reply':
			message = 'New message: '+ item.message
		break;

		case 'upload':
			message = 'New video added'
		break;

		case 'timeline_reaction':
			message = 'Reacted '+item.reaction
		break;

		case 'invite':
			message = 'New collab request: '+item.message
		break;
	}

	const onThumbnailClick = (item)=>
	{
		let dest = '/home'+'?post_key=' + item.post_key;

		if (!!item.slide_key)
			dest += '&slide_key='+item.slide_key;

		if (!!item.position)
			dest += '&tc='+item.position.raw



		return dest
	}
	return (
		<div className={"dm-item  "+(item.visible ? 'visible':'not-visible')}>
			<a href={onThumbnailClick(item)} >
			
			<AvatarComponent
				image={item.sender_avatar}
				name={item.sender_name}
				showName={false}
			/>
			{/*<div className="avatar">
				<img src={item.sender_avatar} />
			</div>*/}
			

			<div className="message">
				<span className={"sender-name "+(item.is_new ? 'new':'')}>{item.sender_name} </span>
				<span className="body">{message} • <span className="since">{timeSince(item.created_at, true)}</span></span>
			</div>
			<div className="thumbnail">
					<img src={config.contentServiceUrl+item.thumbnail_url} />
			</div>
			</a>
		</div>

		)
}
export default function DirectMessages() {

	let [feed, setFeed] = useState([]);
	var [uploadModalOpen, setUploadModalOpen] = useState({open:false, uploadTo:null});
	var [filter, setFilter] = useState(null);
	var user = new User();

	const fetchFeedData = (user)=> {
	
	return fetch(
	  config.DMFeedServiceUrl + "?session_id=" + user.getSessionID(),
	  {
	    method: "GET",
	    mode: "cors", // no-cors, *cors, same-origin
	    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	    redirect: "follow",
	    headers: {
	      Accept: "application/json",
	      "Content-Type": "application/json",
	    },
	  }
	)
	  .then((response) => response.json())
	  .then((response) =>
	    checkIfSuccessful(
	      response,
	      function (r) {
	        return r;
	      },
	      function (r) {
	        return { items: [] };
	      }
	    )
	  );
	}

	function onUploadDone(post, uploadTo) 
	{
		console.log('done',post)
		
		let slides = dict2arr(post.slides);
		let dest = '/home'+
								'?post_key=' + post.key+
								'&slide_key='+slides[0].slide_key;


		document.location.href = dest

	}



	useEffect(()=>{
		let user = new User();
		fetchFeedData(user).then((results)=>{

			

			setFeed(results.items)
		})
	},[])




	const getRecents = (items)=>{
		let recents =  dict2arr(items.reduce((acc, cur)=>{
				if (!acc.hasOwnProperty(cur.sender_key))
				{
					acc[cur.sender_key] = {
						user_avatar: cur.sender_avatar,
						name: cur.sender_name,
						user_key: cur.sender_key
					}
					
				}
				return acc;
			},{}));


		return [...recents]
	}


	const onFilterClick = (item)=>{

		console.log(item);
		setFilter(item.user_key)
	}

	const applyFilter = (items, filter)=>{

		// console.log('applyFilter', filter, filter == null)
		if (filter == null)
		{

			return items.map((item)=>{ return {visible: true ,...item} });	
		}
		else
		{
			return items.map((item)=>{ return {visible: item.sender_key == filter ,...item} });	
			
		}
		
	}

	return (
		<>
			<TopNav 
        user={user}
        homeUrl="/home"
        gridUrl="/grid"
        navHidden={false}

      />
			<div className="dm-view scrollable">
				

				<div className="mid-row">
					

					{/*<h3>Recent</h3>
					<UserBar items={getRecents(feed)} onClick={(item)=>onFilterClick(item)} count={7} />
					

					<div className="filter-control">
					{filter !== null 
						? (
								
									<button
										className="button"
										onClick={()=>setFilter(null)}
									>
										Clear filters
									</button>
								
							)
						: null

					}
					</div>*/}
					

					{applyFilter(feed, filter).map((item, i)=><DMItem key={i} item={item}/>)}


					{feed.length == 0 
						? <h1>You do not have any DMs </h1>
						: null


					}
				</div>


		     
		    </div>
		    <BottomNav 
		    	onAddClick={() => setUploadModalOpen({open:true, uploadTo:null, key:  new Date().getTime() }) } 
		    	homeUrl="/home"
        		dmUrl="/dm"
		    />
		 	<UploadModal
		  		key={uploadModalOpen.key}
		        open={uploadModalOpen.open}
		        handleClose={() => setUploadModalOpen({open:false, uploadTo:null})}
		        // onUploadDone={onUploadDone}
		        onCollabCreated={onUploadDone}
		        returnCollabAsync={false}
		        uploadTo={null}
		        welcomeH1={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}
        		modalH3={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}

		      />
	    </>
	);
}